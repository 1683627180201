/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Poiret+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap');

$base-color: #5050f3;
$base-black-color: #191919;
$base-white-color: #fff;
$dark-background-color: #0e1111;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

body {
    overflow: hidden;
}

h1 {
  font-family: "Poiret One", cursive;
  font-size: 32px;
}

h2 {
  font-family: "Poiret One", cursive;
  font-size: 26px;
}

p, a, span {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 2;
}

a {
  cursor: pointer;
}

.box-section1 {  
    background-color: $base-black-color;
}

.nav-indicator {
    position: absolute;
    left: 0;
    bottom: 0;
    transition: 0.4s;
    height: 2px;
    border-radius:30px;
    z-index: 1;
  } 

.ham {
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    transition: transform 400ms;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.hamRotate.active {
  transform: rotate(45deg);
}

.hamRotate180.active {
  transform: rotate(180deg);
}

.line {
  fill: none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke: #262929;
  stroke-width: 3px;
  stroke-linecap: round;
}

.ham1 .top {
  stroke-dasharray: 40 139;
}

.special-block{
display:flex;

flex-direction: column;
align-items: center;
a{
  font-family: "Lato", sans-serif;
  font-weight: 400!important;
  img{
    width: 300px;
    object-fit: contain;
    cursor: pointer;
  }
}

a{
  padding: 10px 20px;
  background-color: #5050f3 ;
  color: #fff;
  margin-top: 50px;
  font-weight: 900;
  border-radius: 4px;
text-decoration: none;
// font-family: "Poiret One", cursive;
}
}
.special{

  padding-top: 0px!important;
}
.spec{
  background-color: transparent!important;
  transform: scale3d(1,1,1);
  transition:transform .3s ease;
  &:hover{
    transform: scale3d(0.95,0.95,0.95);
  }
}
.ham1 .bottom {
  stroke-dasharray: 40 180;
}

.ham1.active .top {
  stroke-dashoffset: -98px;
}

.ham1.active .bottom {
  stroke-dashoffset: -138px;
}

.nav-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    right: 0;
    left: 0;
    transform: translateX(100%);
    padding: 50px 0px;
    background-color: $base-white-color;
    bottom: 0;
    z-index: 11111;
    transition: all .3s ease; 

    .left_container {
        a {
            display: block;
            padding: 20px 0px;
            text-decoration: none;
            color: $base-black-color;
            text-align: center;
            position: relative;
            &::after{
              content: '';
              position: absolute;
              left: 50%;
              transform:translateX(-50%);
              bottom: 0px;
              height: 4px;
              width: 0px;
              opacity: 0;
              border-radius: 40px;
              transform-origin: left;
              transition: width .3s ease;
              background-color: $base-color;
            }
            &:active {
              color: $base-color;
            }
            &:active::after {
              width: 20px;
              opacity: 1;
            }
            &:hover {
              color: $base-color;
            }
            &:hover::after {
              width: 20px;
              opacity: 1;
            }
        }
    }

    .right_container {
        border: 1px solid transparent;
        flex: 1;
        display: flex;

        h1 {
          margin: auto;
          color: white;
          font-family: "Poiret One", cursive;
        }
    }
}

.showNav {
  transform:translateX(0px);
}

.show-active {
    display: flex;
    animation:showBig 500ms ease forwards 1;
}

@keyframes showBig {
  0%{
    transform:translateX(500px);
  }
  100%{
    transform:translateX(0px);
  }
}

header {
  height: 14vh;
  padding: 0px 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $base-white-color;

  .logo-box {
    height: 80px;

    img {
      height: inherit;
    }
  }

  .closeBtn {
      position: fixed;
      right: 100px;
      display: none;
      z-index: 99999;
  }

  nav {
    height: 100%;
    display: flex;

    a {
      text-decoration: none;
      padding: 0px 15px;
      height: 100%;
      line-height: 14vh;
      color: $base-black-color;
    }
  }
}


.why-section, .killer-apps {
  background-color: $dark-background-color;

  .what-section-main-points {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 20px;

    .what-section-main-point-right {
      margin-right: 20px !important;
    }
    
    .what-section-main-point {
      width: 304px;
      border: 2px solid $base-white-color;
      border-radius: 20px;
      margin: 30px 0 10px 0;
      box-shadow: rgb(255 255 255 / 20%) 0px 5px 15px;

      .what-section-main-point-img-wrapper {
        height: 300px;
        width: 300px;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;

        img {
          height: inherit;
          width: inherit;
          border-top-right-radius: 20px;
          border-top-left-radius: 20px;
        }
      }

      .what-section-main-point-text-wrapper {
        display: flex;
        align-items: center;
        background-color: $base-white-color;
        text-align: center;
        padding: 10px;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        height: 190px;
      }
    }
  }
}

.bullet_two {
  margin-top: 20px;

  p {
    color: $base-white-color;
    text-align: justify;
    padding-bottom: 12px;
    padding-top: 12px;
    padding-left: 50px;
    border-bottom: 1px solid rgb(43, 43, 43);
    position: relative;

    &::after{
      position:absolute;
      left: 0px;
      top:50%;
      transform: translateY(-50%);
      font-size: 24px;
      text-align: center;
      color:rgb(80, 80, 243);
    }
  }
}

.bullet_two{
  p:nth-child(1)::after{
    content:'01';
  }
  
  p:nth-child(2)::after{
    content:'02';
  }

  p:nth-child(3)::after{
    content:'03';
  }

  p:nth-child(4)::after{
    content:'04';
  } 
  
  p:nth-child(5)::after{
    content:'05';
  }

  p:nth-child(6)::after{
    content:'05';
  }

  p:nth-child(7)::after{
    content:'07';
  }
  p:nth-child(8)::after{
    content:'06';
  }
  p:nth-child(10)::after{
    content:'07';
  }
  p:nth-child(12)::after{
    content:'08';
  }
  p:nth-child(14)::after{
    content:'09';
  }
}

.img-class {
  width: 250px;
  padding: 10px 0px;
  margin: 0px auto;

  img {
    width: inherit;
    height: auto;
    object-fit: contain;
  }

}

.mod-img {
  width: 400px;
}

@media screen and (max-width: 1200px){
  .box-section {
    padding: 50px 10px;
  }
  
  .footer-sec {
    flex-direction: column;
  }

  .mod-img{
    width: 300px;
  }
  
  header {
    padding-left: 20px;
    
    .closeBtn {
        position: fixed;
        right: 10px;
        display: flex;
        
        .ham {
          width: 70px !important;
        }
    }
    nav { 
        display: none;
    }
  }
}

@media screen and (max-width: 480px) {
  .nav-container {
    .left_container {
      a {
        padding: 15px 0;
      } 
    }
  }

  header {
    .logo-box {
      height: 60px;
    }
  }

  .closeBtn {
    .ham {
      width: 60px !important;
    }
  }
}


$base-color: #5050f3;
$base-black-color: #191919;
$base-white-color: #fff;
$dark-background-color: #0e1111;

  .container {
    width: 100%;
    height: 86vh;
    scroll-behavior: smooth;
    overflow-y: auto;
  }
  
  .box-section {
    width: 100%;
    padding: 100px;
  }
  
  .rm-section {
    background-color: $base-black-color;
    color: $base-white-color;
  }
  .rm-section .roadmap-block {
    width: 500px;
    margin: 40px auto;
    display: flex;
    position: relative;
    flex-direction: column;
  }
  .rm-section .roadmap-block::after {
    content: "";
    position: absolute;
    width: 10px;
    top: 0;
    bottom: 0;
    border-radius: 40px;
    left: 0;
    background-color: #333;
  }
  .rm-section .roadmap-block .rblock-inside:last-child {
    border: none;
  }
  .rm-section .roadmap-block .rblock-inside {
    flex: 1;
    display: flex;
    padding-top: 30px;
    padding-bottom: 50px;
    padding-left: 30px;
    border-bottom: 1px solid #333;
    flex-direction: column;
    cursor: pointer;
    position: relative;
  }
  .rm-section .roadmap-block .rblock-inside:hover h2 {
    color: $base-white-color;
    transform: translateX(10px);
  }
  .rm-section .roadmap-block .rblock-inside h2 {
    letter-spacing: 1px;
    color: $base-white-color;
    transition: transform 0.3s ease;
  }
  .rm-section .roadmap-block .rblock-inside p {
    margin-top: 10px;
    background-color: #333;
    padding: 10px;
    border-radius: 4px;
  }
  .rm-section .roadmap-block .rblock-inside::after {
    content: "";
    position: absolute;
    left: 0px;
    width: 16px;
    height: 16px;
    border: 4px solid $base-color;
    top: 30px;
    transform: translateX(-32%);
    border-radius: 50%;
    z-index: 11111;
    background: $base-black-color;
    transition: all 0.3s ease;
  }
  
  .heading_block {
    color: $base-white-color;
    position: relative;
  }

  .heading_block h1 {
    letter-spacing: 0.5px;
  }

  .heading_block::after {
    content: "";
    position: absolute;
    height: 5px;
    top: -15px;
    left: 0px;
    width: 50px;
    background-color: $base-color;
    border-radius: 20px;
  }
  
  .para_block {
    color: $base-white-color;
    width: 50%;
    border-bottom: 1px solid $base-color;
    text-align: right;
    padding-bottom: 2px;
  }
  
  .investor-deck {
    background-color: $dark-background-color;
  }
  
  ul {
    display: flex;
    transform-style: preserve-3d;
  }
  
  ul li {
    position: relative;
    list-style: none;
    width: 120px;
    height: 120px;
    margin: 0px 20px;
  }
  
  ul li:before {
    content: "";
    position: absolute;
    bottom: -10px;
    left: -5px;
    width: 100%;
    height: 10px;
    background: $base-color;
    transform-origin: top;
    transform: skewX(-41deg);
  }
  
  ul li:after {
    content: "";
    position: absolute;
    top: 5px;
    left: -9px;
    width: 9px;
    height: 100%;
    background: $base-color;
    transform-origin: right;
    transform: skewY(-49deg);
  }
  
  ul li span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex !important;
    background: $base-color;
    justify-content: center;
    align-items: center;
    text-align: Center;
    color: $base-white-color;
    transition: 1.5s ease-out;
  }
  
  .bg::after {
    content: "";
    position: absolute;
    height: 25px;
    width: 25px;
    bottom: 2px;
    z-index: 11111111;
    left: 50%;
    transform: translateX(-50%);
    background-image: url(assets/images/download-icon.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
  
  ul li:hover span {
    z-index: 1000;
    transition: 0.3s;
    color: $base-white-color;
    box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.5);
  }
  
  ul li:hover span:nth-child(5) {
    transform: translate(40px, -40px);
    opacity: 1;
  }
  
  ul li:hover span:nth-child(4) {
    transform: translate(30px, -30px);
    opacity: 0.8;
  }
  
  ul li:hover span:nth-child(3) {
    transform: translate(20px, -20px);
    opacity: 0.6;
  }
  
  ul li:hover span:nth-child(2) {
    transform: translate(10px, -10px);
    opacity: 0.4;
  }
  
  ul li:hover span:nth-child(1) {
    transform: translate(0px, 0px);
    opacity: 0.2;
  }
  
  ul li:nth-child(1):hover span {
    background: #D69A38 !important;
  }
  
  ul li:nth-child(2):hover span {
    background: #085754 !important;
  }
  
  ul li:nth-child(3):hover span {
    background: #DA2A2A !important;
  }
  
  .formodal {
    position: fixed;
    top: 0;
    min-height: 100vh;
    z-index: 99999999;
    left: 0;
    right: 0;
    background-color: $base-white-color;
  }
  
  .closefrom {
    position: absolute;
    top: 20px;
    cursor: pointer;
    right: 20px;
    outline: none;
    background-color: transparent;
    height: 35px;
    z-index: 10000;
    width: 35px;
    background-image: url(assets/images/close.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border: none;
  }
  
  .wrapper {
    background: transparent;
    height: 300px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .carousel {
    position: relative;
    width: 100%;
    max-width: 300px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  
  .carousel__item {
    display: flex;
    align-items: center;
    position: absolute;
    width: 100%;
    padding: 0 12px;
    opacity: 0;
    filter: drop-shadow(0 2px 2px #555);
    will-change: transform, opacity;
    animation: carousel-animate-vertical 27s linear infinite;
  }
  
  .carousel__item:nth-child(1) {
    animation-delay: calc(3s * -1);
  }
  
  .carousel__item:nth-child(2) {
    animation-delay: calc(3s * 0);
  }
  
  .carousel__item:nth-child(3) {
    animation-delay: calc(3s * 1);
  }
  
  .carousel__item:nth-child(4) {
    animation-delay: calc(3s * 2);
  }
  
  .carousel__item:nth-child(5) {
    animation-delay: calc(3s * 3);
  }
  
  .carousel__item:nth-child(6) {
    animation-delay: calc(3s * 4);
  }
  
  .carousel__item:nth-child(7) {
    animation-delay: calc(3s * 5);
  }
  
  .carousel__item:nth-child(8) {
    animation-delay: calc(3s * 6);
  }
  
  .carousel__item:last-child {
    animation-delay: calc(-3s * 2);
  }
  
  .carousel__item-head {
    border-radius: 50%;
    background-color: #f1f1f1;
    width: 120px;
    height: 120px;
    padding: 5px;
    position: relative;
    margin-right: -45px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .carousel__item-head img {
    height: 80%;
    width: 80%;
    object-fit: contain;
  }
  
  .carousel__item-body {
    width: 100%;
    background-color: $base-white-color;
    padding: 16px 20px 16px 70px;
  }
  
  .title {
    color: $base-color;
  }
  
  @keyframes carousel-animate-vertical {
    0% {
      transform: translateY(100%) scale(0.5);
      opacity: 0;
      visibility: hidden;
    }
    3%, 11.1111111111% {
      transform: translateY(100%) scale(0.7);
      opacity: 0.4;
      visibility: visible;
    }
    14.1111111111%, 22.2222222222% {
      transform: translateY(0) scale(1);
      opacity: 1;
      visibility: visible;
    }
    25.2222222222%, 33.3333333333% {
      transform: translateY(-100%) scale(0.7);
      opacity: 0.4;
      visibility: visible;
    }
    36.3333333333% {
      transform: translateY(-100%) scale(0.5);
      opacity: 0;
      visibility: visible;
    }
    100% {
      transform: translateY(-100%) scale(0.5);
      opacity: 0;
      visibility: hidden;
    }
  }
  .team_section {
    background-color: $dark-background-color;
    position: relative;
  }
  .team_section::after {
    content: "";
    position: absolute;
    height: 100px;
    width: 100px;
    background-color: $base-black-color;
    right: 50px;
    top: -50px;
    transform: rotateZ(45deg);
  }
  .team_section .team-main {
    display: flex;
    height: 400px;
    align-items: center;
  }
  .team_section .team-main .team_head {
    width: 500px;
    height: 250px;
    background-color: $base-white-color;
    border-radius: 4px;
    padding: 10px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .team_section .team-main .team_head .level-one {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .team_section .team-main .team_head .level-one h2 {
    background-color: $base-color;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    padding: 6px 12px;
    padding-right: 50px;
    color: $base-white-color;
  }
  .team_section .team-main .team_head .level-one img {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    margin-right: 20px;
  }
  .team_section .team-main .team_head .level-two {
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;
    margin-top: 20px;
    align-items: center;
  }
  .team_section .team-main .team_head .level-two .left-level {
    position: relative;
  }
  .team_section .team-main .team_head .level-two .left-level::after {
    content: "";
    position: absolute;
    height: 3px;
    width: 40%;
    background-color: $base-color;
    border-radius: 30px;
    top: -15px;
    right: 0;
  }
  .team_section .team-main .team_head .level-two .left-level::before {
    content: "";
    position: absolute;
    height: 3px;
    width: 16%;
    background-color: $base-color;
    border-radius: 30px;
    top: -25px;
    right: 0;
  }
  .team_section .team-main .team_head .level-two .left-level h2 {
    margin-bottom: 4px;
    color: $base-black-color;
  }
  .team_section .team-main .team_head .level-two .left-level p {
    text-align: right;
    color: $base-black-color;
  }
  .team_section .team-main .team_head .level-two a {
    padding: 10px 30px;
    color: $base-white-color;
    background-color: #0078D4;
    border-radius: 2px;
    letter-spacing: 1px;
    text-decoration: none;
  }
  .team_section .team-main .team-members {
    flex: 1;
  }
  
  .godel-sec {
    background: $base-black-color;
    text-align: center;
    color: $base-white-color;
  }

  .godel-sec img {
    width: 500px;
    object-fit: contain;
    margin-bottom: 40px;
  }

  .godel-sec p {
    max-width: 70%;
    margin: 20px auto;
    color: #efefef;
  }

  .godel-sec .btn-container {
    margin-top: 50px;
    display: flex;
    justify-content: center;
  }

  .godel-sec .btn-container a {
    padding: 12px 24px;
    color: $base-white-color;
    background: $base-color;
    margin: 0px 10px;
    border-radius: 4px;
    text-decoration: none;
  }
  .godel-sec .btn-container .diff-btn {
    background: $base-white-color;
    color: $base-color;
  }
  
  .footer-sec {
    padding: 30px 100px;
    display: flex;
    
    background: #0f0f0f;
    align-items: center;
    justify-content: space-between;
  }
  .footer-sec img {
    height: 100px;
  }
  .footer-sec .links-sec a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $base-white-color;
    margin: 15px 0px;
    text-decoration: none;
    letter-spacing: 1px;
  }
  .footer-sec .links-sec a span {
    display: inline-block;
    background: $base-color;
    border-radius: 30px;
    padding: 8px 12px;
    margin-left: 5px;
    letter-spacing: normal;
    margin-left: 20px;
  }
  
  .main-section {
    height: auto;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: $dark-background-color;
  }
  
  .main-section .content {
    margin: 0 100px;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
    color: $base-white-color;
    border-radius: 20px;
    padding: 20px;
  }
  .main-section .content h1 {
    margin-bottom: 10px;
  }

  .main-section .content p {
    color: $base-white-color;
  }
  
  .what-section {
    background: $base-black-color;

    .what-section-main-points {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 20px;

      .what-section-main-point-right {
        margin-right: 20px !important;
      }
      
      .what-section-main-point {
        width: 304px;
        border: 2px solid $base-white-color;
        border-radius: 20px;
        margin: 30px 0 10px 0;
        box-shadow: rgb(255 255 255 / 20%) 0px 5px 15px;

        .what-section-main-point-img-wrapper {
          height: 300px;
          width: 300px;
          border-top-right-radius: 20px;
          border-top-left-radius: 20px;

          img {
            height: inherit;
            width: inherit;
            border-top-right-radius: 20px;
            border-top-left-radius: 20px;
          }
        }

        .what-section-main-point-text-wrapper {
          display: flex;
          align-items: center;
          background: linear-gradient(135deg, white, rgb(230, 230, 240));
          text-align: center;
          padding: 10px;
          border-bottom-left-radius: 15px;
          border-bottom-right-radius: 15px;
          height: 190px;
        }
      }
    }
  }
  
  .bullet_points {
    margin-top: 30px;
  }
  .bullet_points p {
    color: $base-white-color;
    margin-bottom: 8px;
    text-align: justify;
    padding-left: 30px;
    position: relative;
  }
  .bullet_points p::after {
    content: "";
    position: absolute;
    height: 10px;
    width: 10px;
    background-color: $base-color;
    left: 0px;
    top: 10px;
  }
  
  .developer-sec {
    background: $base-black-color;
    position: relative;
  }
  .developer-sec .left-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 30px;
  }
  .developer-sec .left-box .dev-box {
    display: flex;
    flex-direction: column;
    max-width: 320px;
    margin-bottom: 20px;
  }
  .developer-sec .left-box .dev-box .img-box {
    width: 100%;
    background-color: #fff;
    height: 200px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
  }

  .developer-sec .left-box .dev-box .dark-img-box-background {
    background-color: black;
  }
  .developer-sec .left-box .dev-box .img-box img {
    height: inherit;
    width: inherit;
    object-fit: contain;
  }
  .developer-sec .left-box .dev-box .text-box {
    width: 100%;
    height: 150px;
    padding: 10px;
    display: flex;
    margin-right: 10px;
    background: linear-gradient(45deg, #2E2E8D,#3535A1,#3B3BB5,#4242CA,#4949DE,#5050F3,#5353FF);
    margin-bottom: 10px;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
  }
  .developer-sec .left-box .dev-box .text-box p {
    margin: auto;
    color: $base-white-color;
    text-align: center;
    line-height: 1.3;
  }
  
  .killer-apps {
    background-color: $dark-background-color;
  }
  
  .killer-points {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: $base-white-color;
    margin-bottom: 10px;
  }

  .killer-points p {
    flex: 1;
  }

  .killer-points .pulse-circle {
    stroke: $base-color;
  }
  
  circle {
    fill: $base-color;
    transform-origin: center;
  }
  
  .pulse-circle,
  .pulse-circle-2 {
    stroke: $base-color;
    stroke-width: 2px;
    fill: none;
    transform: translate3d(0, 0, 0);
  }
  
  .pulse-circle {
    animation: pulse 1.5s 1s cubic-bezier(0.39, 0.54, 0.41, 1.5) infinite alternate;
  }
  
  .pulse-circle-2 {
    animation: pulse-2 1.5s cubic-bezier(0.39, 0.54, 0.41, 1.5) infinite alternate;
  }
  
  @keyframes pulse {
    0% {
      r: 4;
    }
    50% {
      r: 16;
    }
    60% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes pulse-2 {
    0%, 50% {
      r: 4;
    }
    100% {
      r: 16;
    }
  }
  .link-box {
    width: inherit;
    text-align: center;
    width: 320px;
    display: flex;
  }
  .link-box a {
    padding: 12px 24px;
    color: $base-white-color;
    max-width: 180px;
    background: $base-color;
    margin: auto;
    border-radius: 4px;
    text-decoration: none;
  }
  
  @media screen and (max-width: 480px) {
    .main-section {
      height: auto;
      padding-top: 50px;
      padding-bottom: 50px;
    }
  
    .formodal {
      padding-top: 50px;
    }
  
    .closefrom {
      top: 10px;
      right: 10px;
    }
  
    .main-section .content {
      margin: 0px 20px;
      padding: 15px 10px;
    }
    .main-section .content h1 {
      margin-bottom: 2px;
    }
    .main-section .content p {
      text-align: left;
      line-height: 25px;
    }

    .heading_block::after {
      height: 4px;
    }
  
    .godel-sec img {
      width: 90%;
      margin-bottom: 30px;
    }

    .godel-sec p {
      max-width: 90%;
    }
  
    .team_section::after {
      height: 50px;
      width: 50px;
      top: -30px;
    }
    .team_section .team-main {
      display: flex;
      height: auto;
      padding: 40px 0px;
      flex-wrap: wrap;
      align-items: center;
    }
    .team_section .team-main .team_head {
      width: 500px;
      height: 250px;
      background-color: $base-white-color;
      border-radius: 4px;
      padding: 10px 0px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
    .team_section .team-main .team_head .level-one {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .team_section .team-main .team_head .level-one h2 {
      background-color: $base-color;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      padding: 6px 12px;
      padding-right: 50px;
      color: $base-white-color;
    }
    .team_section .team-main .team_head .level-one img {
      height: 100px;
      width: 100px;
      border-radius: 50%;
      margin-right: 20px;
    }
    .team_section .team-main .team_head .level-two {
      display: flex;
      justify-content: space-between;
      padding: 0px 10px;
      margin-top: 20px;
      align-items: center;
    }
    .team_section .team-main .team_head .level-two .left-level {
      position: relative;
    }
    .team_section .team-main .team_head .level-two .left-level::after {
      content: "";
      position: absolute;
      height: 3px;
      width: 40%;
      background-color: $base-color;
      border-radius: 30px;
      top: -15px;
      right: 0;
    }
    .team_section .team-main .team_head .level-two .left-level::before {
      content: "";
      position: absolute;
      height: 3px;
      width: 16%;
      background-color: $base-color;
      border-radius: 30px;
      top: -25px;
      right: 0;
    }
    .team_section .team-main .team_head .level-two .left-level h2 {
      margin-bottom: 4px;
      color: $base-black-color;
    }
    .team_section .team-main .team_head .level-two .left-level p {
      text-align: right;
      color: $base-black-color;
    }
    .team_section .team-main .team_head .level-two a {
      padding: 10px 30px;
      color: $base-white-color;
      background-color: #0078D4;
      border-radius: 2px;
      letter-spacing: 1px;
      text-decoration: none;
    }
    .team_section .team-main .team-members {
      margin-top: 50px;
      flex: 1;
    }
  
    .rm-section .roadmap-block {
      width: 100% !important;
    }
  
    ul {
      justify-content: space-around;
      flex-wrap: wrap;
      transform: none;
      // margin-top: 100px;
    }
  
    ul li {
      height: 130px;
      width: 130px;
      margin: 20px 0px;
    }
  
    .developer-sec .left-box {
      justify-content: space-around;
    }
  
    .killer-points {
      flex-wrap: wrap;
    }
    .killer-points svg {
      height: 50px;
      width: 50px;
    }
  
    .container {
      height: 90vh;
    }
  
    .footer-sec {
      padding: 30px 10px;
      flex-wrap: wrap;
      justify-content: space-around;
    }
    .footer-sec img {
      height: 70px;
      margin-bottom: 50px;
    }

    .what-section, .why-section, .killer-apps {
      .what-section-main-points {
        .what-section-main-point {
          width: 274px;
  
          .what-section-main-point-img-wrapper {
            height: 270px;
            width: 270px;
          }
        }
      }
    }
  }

  .diff-btn{
    background-color: $base-color !important;
    color: $base-white-color!important;
  }

  @media screen and (max-width: 840px) {
    .box-section {
      padding: 50px 20px;
    }

    .developer-sec .left-box {
      justify-content: center;
    }
  }

  @media screen and (max-width: 827px) {
    .what-section .what-section-main-points {
      justify-content: center;

      .what-section-main-point {
        margin-right: 0 !important;
      }
    }

    .why-section .what-section-main-points {
      justify-content: center;

      .what-section-main-point {
        margin-right: 0 !important;
      }
    }
  }

  @media screen and (max-width: 647px) {
    .special-block{
      display:flex;
      padding-top: 50px;
      flex-direction: column;
      align-items: center;
      img{
        width: 80%;
        object-fit: contain;
        cursor: pointer;
      }
      a{
        padding: 10px 20px;
        background-color: #5050f3 ;
        color: #fff;
        margin-top: 50px;
        font-weight: 900;
        border-radius: 4px;
      text-decoration: none;
     
      }
      }

    .killer-apps .what-section-main-points {
      justify-content: center;

      .what-section-main-point {
        margin-right: 0 !important;
      }
    }
  }

  
  @media screen and (max-width: 360px) {
    .footer-sec .links-sec {
      a {
        font-size: 14px;

        span {
          font-size: 14px;
        }
      }
    }
  }

  #block4 {
    .heading_block {
      margin-bottom: 40px;
    }
  }

  .jota-form {
    min-width: 100%;
    height: 100vh;
    border: none;
  }
  